import React, { useContext } from 'react';
import MediaQueryContext from '../contexts/MediaQueryContext';
import PCHeader from './header/PCHeader';
import PhoneHeader from './header/PhoneHeader';

const Header: React.FC = () => {
    const isPhone = useContext(MediaQueryContext);

    if (isPhone) {
        return <PhoneHeader />
    } else {
        return <PCHeader />
    }
}

export default Header;