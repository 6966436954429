import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Measure from 'react-measure';
import { useContext } from 'react';
import HeaderSizeContext from '../../contexts/HeaderSizeContext';

const HeaderStyle = styled.header`
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 2rem;
    background-color: black;
    color: white;
`

const Menu = styled.div`
    display: flex;
    justify-content: flex-end;
    color: white;
`
const H1Style = styled.h1`
    display: block;
`

const TitleLink = styled(Link)`
    color: white;
    &:hover, &:focus, &:active, &:visited {
        color: white;
        text-decoration: none;
    }
`

const LinkStyle = styled(Link)`
    display: block;
    margin-left: 3rem;
    font-size: 1.5rem;
    color: white;
    &:hover, &:focus, &:active, &:visited {
        color: white;
    }
`

const PCHeader: React.FC = () => {
    const [, setHeight] = useContext(HeaderSizeContext);

    return (
        <Measure bounds onResize={(contentRect) => {setHeight(contentRect.bounds?.height || 0)}}>
            {({measureRef}) =>
                <HeaderStyle ref={measureRef}>
                    <H1Style><TitleLink to="/">ladder-to-shangri-la.blue</TitleLink></H1Style>
                    <Menu>
                        <LinkStyle to="/">Top</LinkStyle>
                        <LinkStyle to="/about">About</LinkStyle>
                        <LinkStyle to="/works">Works</LinkStyle>
                    </Menu>
                </HeaderStyle>
            }
        </Measure>
    );
}

export default PCHeader;