import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion, useCycle } from 'framer-motion';
import HeaderSizeContext from '../../contexts/HeaderSizeContext';
import Measure from 'react-measure';

const HeaderStyle = styled(motion.header)`
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    background-color: black;
    color: white;
    overflow: hidden;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
`

const H1Style = styled.h1`
    display: block;
    margin: 0;
    font-size: 2rem;
    line-height: 2rem;
`

const TitleLink = styled(Link)`
    color: white;
    &:hover {
        color: white;
        text-decoration: none;
    }
`

const Nav = styled(motion.nav)`
    height: 23px;
    width: 23px;
`

const Button = styled.button`
    height: 23px;
    width: 23px;
    padding: 0;
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    background: transparent;
`

const MenuList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1px;
    cursor: pointer;
`

const LinkStyle = styled(Link)`
    display: block;
    width: 100%;
    padding: 3rem 0;
    text-align: center;
    font-size: 2rem;
    color: white;
    &:hover, &:focus, &:visited {
        color: white;
        text-decoration: none;
    }
    &:active {
        background-color: white;
        color: black;
        text-decoration: none;
    }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Path = (props: any) => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 100%)"
        strokeLinecap="round"
        {...props}
    />
);

const MenuToggle: React.FC<{toggle: () => void}> = ({ toggle }) => (
    <Button onClick={toggle}>
        <svg width="23" height="23" viewBox="0 0 23 23">
            <Path
                variants={{
                    closed: { d: 'M 2 2.5 L 20 2.5' },
                    open: { d: 'M 3 16.5 L 17 2.5' }
                }}
            />
            <Path
                d="M 2 9.423 L 20 9.423"
                variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 }
                }}
                transition={{ duration: 0.1 }}
            />
            <Path
                variants={{
                    closed: { d: 'M 2 16.346 L 20 16.346' },
                    open: { d: 'M 3 2.5 L 17 16.346' }
                }}
            />
        </svg>
    </Button>
);

const PhoneHeader: React.FC = () => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const [menuHeight, setMenuHeight] = useState<number>(0);
    const [titleHeight, setTitleHeight] = useContext(HeaderSizeContext);
    const header = useRef<HTMLHeadingElement>(null);

    return (
        <HeaderStyle 
            initial={false} 
            animate={{height: isOpen ? `${titleHeight+menuHeight}px` : '6rem'}}
            transition={{ease: 'easeOut', duration: 0.15}}
            ref={header}
        >
            <Measure bounds onResize={(contentRect) => {setTitleHeight(contentRect.bounds?.height || 0)}}>
                {({measureRef}) => 
                    <TitleContainer ref={measureRef}>
                        <H1Style><TitleLink to="/">ladder-to-shangri-la.blue</TitleLink></H1Style>
                        <Nav animate={isOpen ? 'open' : 'closed'}>
                            <MenuToggle toggle={() => toggleOpen()} />
                        </Nav>
                    </TitleContainer>
                }
            </Measure>
            <Measure bounds onResize={(contentRect) => {setMenuHeight(contentRect.bounds?.height || 0)}}>
                {({measureRef}) =>
                    <MenuList ref={measureRef} onClick={() => toggleOpen()}>
                        <LinkStyle to="/about">About</LinkStyle>
                        <LinkStyle to="/works">Works</LinkStyle>
                    </MenuList>
                }
            </Measure>
        </HeaderStyle>
    );
}

export default PhoneHeader;