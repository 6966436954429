import React, { Suspense, lazy, useState } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Div100vh from 'react-div-100vh';

import Header from './components/Header';
import HeaderSizeContext from './contexts/HeaderSizeContext';
import useMedia from 'use-media';
import MediaQueryContext from './contexts/MediaQueryContext';
import styled from 'styled-components';

const TopPage = lazy(() => import('./pages/TopPage'));
const About = lazy (() => import('./pages/About'));
const Works = lazy (() => import('./pages/Works'));
const WorkPage = lazy (() => import('./pages/WorkPage'));
const AfterwordPage = lazy (() => import('./pages/AfterwordPage'));
const ScrollPage = lazy (() => import('./pages/Scrolls'));
const TheMajesticAfterword = lazy (() => import('./components/scrolls/majestic/Afterword'));
const NotFound = lazy (() => import('./pages/NotFound'));

const LoadingStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Layout: React.FC = (props) => {
    return (
        <>
            <Header />
            {props.children}
        </>
    );
}

const Loading = () => {
    const [height, setHeight] = useState<number>(0);
    const isPhone = useMedia({maxWidth: '37.5rem'});

    return (
        <MediaQueryContext.Provider value={isPhone}>
            <HeaderSizeContext.Provider value={[height, setHeight]}>
                <Header />
                <LoadingStyle>
                    <p>Loading...</p>
                </LoadingStyle>
            </HeaderSizeContext.Provider>
        </MediaQueryContext.Provider>
    );
}

const Index: React.FC = () => {
    const location = useLocation();
    const [, rootPath] = location.pathname.split('/');
    const [height, setHeight] = useState<number>(0);
    const isPhone = useMedia({maxWidth: '769px'});

    return (
        <Suspense fallback={Loading()}>
            <Div100vh>
                <MediaQueryContext.Provider value={isPhone}>
                    <HeaderSizeContext.Provider value={[height, setHeight]}>
                        <AnimatePresence exitBeforeEnter initial={false}>
                            <Layout>
                                <Switch location={location} key={rootPath}>
                                    <Route exact path="/" component={TopPage} />
                                    <Route exact path="/about" component={About} />
                                    <Route exact path="/works" component={Works} />
                                    <Route exact path="/works/scrolls/the_majestic/afterword" component={TheMajesticAfterword} />
                                    <Route path="/works/scrolls/:slug/:page?" component={ScrollPage} />
                                    <Route path="/works/:series/:title/afterword" component={AfterwordPage} />
                                    <Route path="/works/:series/:title" component={WorkPage} />
                                    <Route component={NotFound} />
                                </Switch>
                            </Layout>
                        </AnimatePresence>
                    </HeaderSizeContext.Provider>
                </MediaQueryContext.Provider>
            </Div100vh>
        </Suspense>
    );
}

render(
    <Router>
        <Index />
    </Router>,
    document.getElementById('app')
)